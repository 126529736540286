@import '../../../../scss/theme-bootstrap';

.waitlist-form {
  max-width: 425px;
  height: auto;
  margin: auto;
  text-align: center;
  @if $cr18 == true {
    max-width: 528px;
    max-height: 319px;
    height: 100%;
    &__container {
      padding: 0 15%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__header {
    @include font--subheading;
    color: $color-green;
    text-transform: uppercase;
    font-size: 55px;
    margin-bottom: 14px;
    @if $cr18 == true {
      color: $color-cr18-black;
      font-size: 30px;
    }
    &.temp-out-of-stock {
      padding-top: 10%;
      font-size: 35px;
    }
  }
  &__message {
    line-height: 1.5em;
    font-size: 16px;
    @if $cr18 == true {
      font-size: 15px;
      margin-bottom: 15px;
    }
  }
  @if $cr18 == true {
    &__submit {
      @include cr18-btn-primary;
      width: 30%;
      border: none;
      float: right;
    }
  }
  input.waitlist-form__email {
    width: 265px;
    @if $cr18 == true {
      width: 60%;
      float: left;
      height: 30px;
      font-size: 15px;
      line-height: 30px;
      padding: 0 10px;
      border: solid 1px $color-cr18-black;
      @include input-placeholder {
        color: $color-cr18-black;
      }
    }
  }
}
