/* Buttom style mixins for creative refresh 2018 */

@mixin cr18-btn-primary() {
  @include primary-hover;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  font-family: $font--futura-book;
  background-color: $color-cr18-black;
  color: $color-white;
  padding: 0 15px;
  font-weight: 200;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
}

@mixin cr18-btn-primary-option() {
  @include cr18-btn-primary;
  text-transform: none;
}

@mixin cr18-btn-secondary() {
  @include secondary-hover;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-family: $font--futura-book;
  color: $color-drak-black;
  border-bottom: none;
  text-align: center;
}

@mixin cr18-btn-size--outstock() {
  color: $color-new--gray;
  border-color: $color-mid-gray;
  text-decoration: line-through;
}

@mixin primary-hover() {
  &:hover {
    background-color: $color-green;
    text-decoration: none;
  }
}

@mixin secondary-hover() {
  &:hover {
    color: $color-green;
    text-decoration: none;
  }
}

@mixin button {
  @include button-base;
  height: 2.5rem;
  line-height: 2.5;
}

@mixin button-base {
  @include inline-block;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;

  &:focus {
    outline: 0;
  }
  &.button--block {
    display: block;
  }
}

@mixin button--dark {
  @include background($color-black);
  @include link--bold;
  text-decoration: none; // override from .link--bold
  color: $color-white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin button--secondary {
  @include button-base;
  height: 2rem;
  line-height: 2;
}

// content block buttons
@mixin content-block-btn-primary() {
  @include primary-hover;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 40px;
  line-height: 1;
  font-size: 18px;
  font-family: $font--futura-book;
  background-color: $color-cr18-black;
  color: $color-white;
  padding: 0 18px;
  font-weight: 200;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid transparent;
}

@mixin content-block-btn-secondary() {
  @include content-block-btn-secondary-hover;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 40px;
  line-height: 1;
  font-size: 18px;
  font-family: $font--futura-book;
  background: none;
  color: $color-cr18-black;
  border: 1px solid $color-cr18-black;
  padding: 0 18px;
  font-weight: 200;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
}

@mixin content-block-btn-tertiary() {
  @include content-block-btn-tertiary-hover;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  font-size: 18px;
  font-family: $font--futura-book;
  color: $color-cr18-black;
  font-weight: 200;
  background: none;
  border: none;
  text-decoration: underline;
  text-transform: none;
  padding: 0;
  height: auto;
}

@mixin content-block-btn-inactive() {
  @include content-block-btn-primary;
  background-color: $color-medium-gray;
  cursor: default;
  border: 1px solid transparent;
  &:hover {
    color: $color-white;
    cursor: default;
    background-color: $color-medium-gray;
  }
}

@mixin content-block-btn-secondary-hover() {
  &:hover {
    background: none;
    border-color: $color-moss-green;
    color: $color-moss-green;
    text-decoration: none;
  }
}

@mixin content-block-btn-tertiary-hover() {
  &:hover {
    background: none;
    border: none;
    color: $color-moss-green;
    text-decoration: underline;
  }
}
